<template>
<div>
</div>
</template>

<script>
import { useClient, useMutation } from 'villus';
import { gql } from 'graphql-tag';
import { useStore } from 'vuex';

export default {
  name: 'loginMutation',

  setup() {
    const store = useStore();

    useClient({
      url: process.env.VUE_APP_STRAPI,
    });

    const loginMutation = gql`
      mutation($email: String!, $pass: String!) {
        login(input: { identifier: $email, password: $pass }) {
          jwt
          user {
            id
          }
        }
      }
      `;

    const { execute: loginUser } = useMutation(loginMutation);
    store.commit('setStrapiExecutionHandler', { item: 'loginUser', result: loginUser });
  },
};
</script>
