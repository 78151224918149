<template>
  <div>
    <darkBackground />
    <login-mutation />
    <forgotPasswordMutation />
    <infoOverlay :text=infoMessage :index="1" />
    <infoOverlay text="Dein Account ist keinem Kurs zugeordnet. Bitte wende ich an einen Administrator" :index="2" />
    <div class="w-full min-h-screen flex flex-row text-new-black font-visby" :class="{'pt-safe' : parseInt(sat) !== 0}" >
      <div class="hidden md:block w-1/2 h-full min-h-screen bg-new-yellow bg-center bg-no-repeat bg-cover" :style="{ backgroundImage: 'url(' + require(`../../assets/images/${imagePath}`) + ')' }">
        <div class="w-full h-full bg-black opacity-5"></div>
      </div>
      <div v-if="!maintainanceMode" class="w-full md:w-1/2 h-full min-h-screen bg-new-yellow flex flex-col justify-start md:justify-center items-center">
        <div class="p-5 w-full h-full md:w-96 md:p-0 flex flex-col items-center md:justify-center pt-24">
          <logoSVG class="h-auto w-5/6 flex-shrink-0"/>

          <div class="text-new-black text-center mt-12">
            <span>Logge dich mit Deiner Email Adresse in die {{ appNameLong }} ein.</span>
          </div>
          <!-- login Form -->
            <form id="login" @submit.prevent="login(email, pass)" class="w-full mt-5">
            <div class="w-full bg-white rounded-xl shadow-new mt-5 border border-new-yellow2">

              <!-- email -->
              <div class="relative w-full h-16 p-3 flex flex-col justify-between items-start border-b border-new-yellow">
                <div class="transition duration-150 ease-in-out absolute inset-x-3 top-3 pl-2 h-4 text-new-darkGrey text-xs opacity-0" :class="{ 'opacity-100' : email !== '' }">Email</div>
                <input id="email" ref="email" name="email" autocomplete="username" v-model="email" type="email" placeholder="Email"
                class="transition duration-150 ease-in-out pl-2 h-full text-base bg-transparent w-full appearance-none outline-none placeholder-new-darkGrey flex flex-row justify-center items-center"
                :class="{'translate-y-2 transform-gpu' : email !== '' }" required/>
                <atSVG class="absolute h-full top-0 right-5"/>
              </div>


              <!-- pass -->
              <div class="relative w-full h-16 p-3 flex flex-col justify-between items-start">
                <div class="transition duration-150 ease-in-out absolute inset-x-3 top-3 pl-2 h-4 text-new-darkGrey text-xs opacity-0" :class="{ 'opacity-100' : pass !== '' }">Passwort</div>
                <input id="pass" type="password" name="pass" autocomplete="current-password" v-model="pass"  placeholder="Passwort"
                class="transition duration-150 ease-in-out pl-2 h-full text-base bg-transparent w-full appearance-none outline-none placeholder-new-darkGrey flex flex-row justify-center items-center"
                :class="{'translate-y-2 transform-gpu' :  pass !== '' }" required/>
                <lockSVG class="absolute h-full top-0 right-5"/>
              </div>

            </div>
            <div class="flex flex-row justify-between items-center mt-3 mb-1">
              <div class="flex flex-row justify-start items-center  cursor-pointer" @click="toggleCheckbox">
                <div class="w-5 h-5 rounded-full bg-white shadow-new flex flex-row justify-center items-center">
                  <checkSVG v-if="checked" color='black' class="p-0.5" />
                </div>
                <div class="ml-2 text-xs font-bold text-new-black">Eingeloggt bleiben</div>
              </div>
            </div>

            <div class="fixed md:static left-0 bottom-0 p-5 md:p-0 md:mt-8 min-h-28 w-full bg-new-yellow border-t-2 border-new-yellow2 md:border-none">
              <input v-if="!loading" type="submit" value="Einloggen" class="text-lg w-full text-white bg-new-black flex flex-col justify-center items-center rounded-lg shadow-lg p-5 cursor-pointer" />
              <div v-else class="text-lg w-full text-white bg-new-black flex flex-col justify-center items-center rounded-lg shadow-lg p-5">
                <spinSVG :light="true" class="-ml-1 mr-3" />
              </div>
            </div>
          </form>

          <div class="md:hidden h-36 flex-shrink-0"></div>

        </div>
      </div>
      <div v-else class="w-full md:w-1/2 h-full min-h-screen bg-new-yellow- flex flex-col justify-center items-center">
        <div class="text-xl">Wartungsarbeiten</div>
        <div>Die App wird gerade überarbeitet und steht dir in wenigen Tagen wieder zur Verfügung.</div>
      </div>
    </div>
</div>
</template>

<script>
// SVG
import logoSVG from '../../assets/svg/logo.vue';
import atSVG from '../../assets/svg/at.vue';
import lockSVG from '../../assets/svg/lock.vue';
import checkSVG from '../../assets/svg/check.vue';
import spinSVG from '../../assets/svg/spin.vue';

// Components
import darkBackground from '../../symbols/darkBackground.vue';
import infoOverlay from '../../symbols/infoOverlay.vue';

// GraphQL
import loginMutation from './strapi/loginMutation.vue';
import forgotPasswordMutation from './strapi/forgotPasswordMutation.vue';

// Mixins
import showHide from '../../mixins/animations/showHide.vue';
import detectNotch from '../../mixins/detectNotch.vue';
import iosVibrations from '../../mixins/iosVibrations.vue';

export default {
  name: 'login',
  components: {
    loginMutation, forgotPasswordMutation, logoSVG, atSVG, lockSVG, checkSVG, darkBackground, infoOverlay, spinSVG,
  },
  mixins: [showHide, iosVibrations, detectNotch],

  data() {
    return {
      appNameLong: process.env.VUE_APP_NAMELONG?.replace('-', ' '),
      imagePath: process.env.VUE_APP_IMAGE,
      triggerEmail: false,
      email: '',
      pass: '',
      checked: false,
      infoMessage: null,
      maintainanceMode: false,
    };
  },

  computed: {
    loading() {
      return this.$store.state.auth.isLoading;
    },
    loginUser() {
      return this.$store.state.loginUser;
    },
    forgotPassword() {
      return this.$store.state.forgotPassword;
    },
    queryUserIdEx() {
      return this.$store.state.queryUserIdEx;
    },
  },

  mounted() {
    this.$nextTick(() => {
      if (window.localStorage.rememberMe) {
        this.checked = (window.localStorage.rememberMe === 'true');
      }
      if (this.isAuthenticated) {
        this.$router.replace({ name: 'dashboard' });
      } else if (window.localStorage.app) {
        this.$store.commit('setLoading', true);
        this.$store.commit('loginUserFromSession', window.localStorage.app);
        this.queryUserIdEx().then((result) => {
          if (result.error) {
            window.localStorage.removeItem('app');
            this.$store.commit('setLoading', false);
          } else {
            this.$store.commit('loginUserFromSessionTwo', result.data);
          }
        });
      }
    });
  },

  methods: {

    toggleCheckbox() {
      this.checked = !this.checked;
      window.localStorage.setItem('rememberMe', this.checked);
    },

    toggleInfoWindow(message) {
      this.infoMessage = message;
      this.iosVibrate(1);
      this.$store.commit('toggleInfoOverlay', { value: true, index: 1 });
    },

    login(email, pass) {
      this.iosVibrate(1);
      this.$store.commit('setLoading', true);

      this.loginUser({ email, pass }).then((result) => {
        if (result.error) {
          if (result.error.message.includes('Invalid')) this.toggleInfoWindow('Login nicht möglich. Falsche Email oder Passwort.');
          this.$store.commit('setLoading', false);
        } else {
          this.$store.commit('loginUser', result.data);
        }
      });
    },
  },

};
</script>

<style>
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
   -webkit-transition-delay: 9999s;
   transition-delay: 9999s;
}
</style>
