<script>
import anime from 'animejs/lib/anime.es.js';

export default {
  name: 'showHide',
  methods: {

    showInfo(el, done) {
      const parent = el.parentElement;
      const timeline = anime.timeline({
        easing: 'easeInSine',
        duration: 350,
      });
      timeline
        .add({
          targets: parent,
          height: ['3rem', '4rem'],
        })
        .add({
          targets: el,
          opacity: [0, 1],
          complete: done,
        }, 350);
    },

    hideInfo(el, done) {
      const parent = el.parentElement;
      const timeline = anime.timeline({
        easing: 'easeInSine',
        duration: 350,
      });
      timeline
        .add({
          targets: el,
          opacity: [1, 0],
        })
        .add({
          targets: parent,
          height: ['4rem', '3rem'],
          complete: done,
        }, 350);
    },
  },
};
</script>
